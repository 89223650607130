import { render, staticRenderFns } from "./quickRegisterBlock.vue?vue&type=template&id=2fe8ee76&scoped=true"
import script from "./quickRegisterBlock.vue?vue&type=script&lang=js"
export * from "./quickRegisterBlock.vue?vue&type=script&lang=js"
import style0 from "./quickRegisterBlock.vue?vue&type=style&index=0&id=2fe8ee76&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2fe8ee76",
  null
  
)

export default component.exports