<template>
  <div class="docmentary-wrap" :class="newTheme">
    <div class="main-wrap">
      <h2 class="gd-title">{{ $t('new_index.docmentary.title') }}</h2>
      <div class="gd-desc">{{ $t('new_index.docmentary.samll_tit') }}</div>
      <div class="gd-small">{{ $t('new_index.docmentary.desc') }}</div>
      <div class="top-num-main">
        <div class="num-item">
          <div class="num-wrap">{{ `${formatMoney(this.tradersCount)}+` }}</div>
          <div class="num-desc">{{ $t('new_index.docmentary.info1') }}</div>
        </div>
        <div class="num-item">
          <div class="num-wrap">{{ `${formatMoney(this.followersCount)}+` }}</div>
          <div class="num-desc">{{ $t('new_index.docmentary.info2') }}</div>
        </div>
        <div class="num-item">
          <div class="num-wrap">{{ `$${formatMoney(this.tradingVolume)}+` }}</div>
          <div class="num-desc">{{ $t('new_index.docmentary.info3') }}</div>
        </div>
      </div>
      <nuxt-link class="gd-btn" :to="$i18n.path('copyTradingPro')">
        {{ $t('new_index.docmentary.btn') }}
        <img alt="" class="td-btn-arrow" src="~assets/img/new_index/black-arrow.svg" />
      </nuxt-link>
    </div>
    <!-- <div class="gd-swiper-wrap" v-if="documentaryList.length" @mouseleave="onMouseLeave" @mouseover="handlerMouseOver">
      <div v-swiper:mySwiper="swiperOption" ref="mySwiper">
        <div class="swiper-wrapper">
          <div class="swiper-slide" v-for="(item, index) in documentaryList" :key="index">
            <trader-item :data="item" :style-data="{width:'312px'}"
                         :show-follow-protocol.sync="showFollowProtocol" :trader-id.sync="traderId">
            </trader-item>
          </div>
        </div>
      </div>
    </div> -->
    <div class="swiper-block" v-if="documentaryList.length">
      <section class="swiper-row" ref="swiper-row" :style="{width:swiperRowWidth,animationDuration: animationDuration, animationName: keyframesName}">
        <div class="swiper-slide" v-for="(item, index) in documentaryList" :key="index">
            <trader-item :data="item" :style-data="{width:'312px'}"
                          :show-follow-protocol.sync="showFollowProtocol" :trader-id.sync="traderId">
            </trader-item>
          </div>
      </section>
  </div>

    <!-- 合约协议 -->
    <follow-protocol @opened="openedFollowProtocol" :show.sync="showFollowProtocol"></follow-protocol>
  </div>
</template>

<script>
import tradingApi from '~/server/newTradingApi';
import { config } from '~/config/enum.conf.js';
import { mapGetters, mapState } from 'vuex';
import traderItem from './traderItem';
import followProtocol from "~/components/common/FollowProtocol";

export default {
  components: {
    'trader-item': traderItem,
    'follow-protocol': followProtocol,
  },
  data() {
    return {
      animationDuration: 10,
      keyframesName: 'documentary-swiper-animation-name-93udh32',
      swiperRowWidth: '1400px',
      swiperPause: false,
      countTimer: null,
      tradersCount: 3500,
      followersCount: 35000,
      tradingVolume: 720000000,
      documentaryList: [],
      curIndex: 0,
      swiperOption: {
        loop: true,
        loopedSlides: 5,
        slidesPerView: 5,
        spaceBetween: 16,
      },
      traderId: '',
      showFollowProtocol: false,
      timers: []
    };
  },
  computed: {
    ...mapState(['newTheme', 'locale']),
    ...mapGetters(['isLogin'])
  },
  watch:{
    documentaryList(val, preVal) {
      if (val.length > 4 && val.length !== preVal.length) {
        this.swiperRowWidth = `${392 * (this.documentaryList.length)}px`;
        this.createKeyframes(`-${392 * (this.documentaryList.length) - 1840}px`);
        this.animationDuration = `${(392 * (this.documentaryList.length) - 1440) / 100}s`;
      }
    },

  },
  mounted() {
    this.getList();
    window.addEventListener('scroll', () => {
      const scrollY = window.pageYOffset || document.documentElement.scrollTop;
      if (scrollY >= 3000 && !this.countTimer) {
        this.autoIncrease();
      }
    });
  },
  beforeDestroy() {
    this.countTimer && clearTimeout(this.countTimer);
    this.countTimer = null;
    if (this.$refs.mySwiper && this.$refs.mySwiper.swiper) {
      this.$refs.mySwiper.swiper.destroy();
      this.$refs.mySwiper.swiper = null;
    }
    this.$children.forEach(child => {
      child.$destroy()
      child = null
    })
    this.$children.length = 0
    // 循环清除定时器
    this.timers.forEach(e => {
      e && clearTimeout(e);
    })
    this.timers = []
  },
  methods: {
    formatMoney(num) {
      return num.toLocaleString('en-US');
    },
    autoIncrease() {
      this.countTimer && clearTimeout(this.countTimer);
      let tradersDone = true;
      let followersDone = true;
      let tradingVolumeDone = true;
      // 循环50次
      if (this.tradersCount < 5000) {
        this.tradersCount += 20;
        tradersDone = false;
      }
      if (this.followersCount < 50000) {
        this.followersCount += 200;
        followersDone = false;
      }
      if (this.tradingVolume < 800000000) {
        this.tradingVolume += 1000000;
        tradingVolumeDone = false;
      }
      if (tradersDone && followersDone && tradingVolumeDone) {
        this.tradersCount = 5000;
        this.followersCount = 50000;
        this.tradingVolume = 800000000;
        return;
      }
      this.countTimer = setTimeout(() => {
        this.autoIncrease();
      }, 50);
    },
    onMouseLeave() {
      this.swiperPause = false;
 
    },
    handlerMouseOver(){
      this.swiperPause = true;
      
    },
    getList() {
      let params = {
        pageNo: 1,
        pageSize: 9,
      }
      tradingApi.topTraderListView(params).then(res => {
        if (res.code === config.error.SUCCESS && res.data) {
          const list = res.data || [];
          let data = list.find(item => item.tab === 'nWeekFollowerProfit'); // 获取近三周跟单收益最高的9条数据
          if(data&&data.list&&data.list.length>0){
            data.list.push(data.list[0]);
          }
          this.documentaryList = (data && data.list) || [];
        }
      })
    },
    // 开通合约后，去设置
    openedFollowProtocol() {
      this.$router.push({
        path: this.$i18n.path("copyTradingPro/setting"),
        query: {id: this.traderId}
      });
    },
        createKeyframes(moveX) {
      const styleSheet = document.styleSheets[document.styleSheets.length - 1];
      const keyframesStr = `${this.keyframesName} {
      0% {
        transform: translateX(0);
        }

        100% {
        transform: translateX(${moveX});
        }
      }`.replace(/\s+/g, '');
      const keyframes = `@keyframes ${keyframesStr}`;
      const keyframes_webdit = `@-webkit-keyframes ${keyframesStr}`; /* Safari 和 Chrome */

      styleSheet.insertRule(keyframes, styleSheet.rules.length);
      styleSheet.insertRule(keyframes_webdit, styleSheet.rules.length);
    },

  }
};
</script>
<style lang="less" scoped>
@media screen and (min-width: 1201px){
  .docmentary-wrap {
    background: @black-bg-primary-V2;
    padding: 100px 0 64px;
    .main-wrap {
      width: 1200px;
      overflow: hidden;
      margin: auto;
      position: relative;
      .gd-title {
        font-size: 24px;
        .bold-font();
        color: @black-text-primary-V2;
      }
      .gd-desc {
        width: 740px;
        font-size: 56px;
        .medium-font();
        color: @black-text-primary-V2;
        margin-bottom: 40px;
      }
      .gd-small {
        font-size: 20px;
        color: @black-text-tertiary-V2;
      }
      .gd-btn {
        min-width: 192px;
        height: 64px;
        line-height: 64px;
        border-radius: 24px;
        padding: 0 24px;
        font-size: 18px;
        .medium-font();
        color: @black-bg-primary-V2;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: @black-btn-tertiary-default-V2;
        position: absolute;
        right: 0;
        top: 84px;
        &:hover {
          background-color: @black-btn-tertiary-hover-V2;
        }
        .td-btn-arrow {
          width: 24px;
          height: 24px;
        }
      }
      .top-num-main {
        margin-top: 48px;
        display: flex;
        justify-content: space-between;
        padding: 32px 16px;
        border-top: 1px solid @black-border-heavy-V2;
        border-bottom: 1px solid @black-border-heavy-V2;
        .num-wrap {
          color: @black-text-primary-V2;
          font-size: 32px;
          .bold-font();
          margin-bottom: 16px;
        }
        .num-desc {
          font-size: 14px;
          color: @black-text-secondary-V2;
        }
      }
    }
    .gd-swiper-wrap {
      margin-top: 51px;
      // transform: translateX(-144px);
      margin-right: 32px;
      padding-top: 16px;
      .swiper-slide {
        width: 360px;
        margin-right: 32px !important;
      }
      .swiper-wrapper{
        padding-top: 16px;
      }
    }
    .swiper-block{
      margin-top: 51px;
      padding-top: 16px;
      overflow: hidden;
      .swiper-row{
        padding-top: 16px;
        animation: 5s linear infinite;
        display: flex;
        &:hover {
          animation-play-state: paused;
        }
      .swiper-slide {
        width: 360px;
        margin-right: 32px !important;
      }
    }
    }

  }
}

@media screen and (min-width: 1020px) and (max-width: 1199px) {
   .docmentary-wrap {
    background: @black-bg-primary-V2;
    padding: 100px 0 64px;
    .main-wrap {
      width: 940px;
      overflow: hidden;
      margin: auto;
      position: relative;
      .gd-title {
        font-size: 24px;
        .bold-font();
        color: @black-text-primary-V2;
      }
      .gd-desc {
        width: 668px;
        font-size: 56px;
        .medium-font();
        color: @black-text-primary-V2;
        margin-bottom: 40px;
      }
      .gd-small {
        font-size: 20px;
        color: @black-text-tertiary-V2;
      }
      .gd-btn {
        min-width: 192px;
        height: 64px;
        line-height: 64px;
        border-radius: 24px;
        padding: 0 24px;
        font-size: 18px;
        .medium-font();
        color: @black-bg-primary-V2;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: @black-btn-tertiary-default-V2;
        position: absolute;
        right: 0;
        top: 84px;
        &:hover {
          background-color: @black-btn-tertiary-hover-V2;
        }
        .td-btn-arrow {
          width: 24px;
          height: 24px;
        }
      }
      .top-num-main {
        margin-top: 48px;
        display: flex;
        justify-content: space-between;
        padding: 32px 16px;
        border-top: 1px solid @black-border-heavy-V2;
        border-bottom: 1px solid @black-border-heavy-V2;
        .num-wrap {
          color: @black-text-primary-V2;
          font-size: 32px;
          .bold-font();
          margin-bottom: 16px;
        }
        .num-desc {
          font-size: 14px;
          color: @black-text-secondary-V2;
        }
      }
    }
    .gd-swiper-wrap {
      margin-top: 51px;
      // transform: translateX(-144px);
      margin-right: 32px;
      padding-top: 16px;
      .swiper-slide {
        width: 360px;
        margin-right: 32px !important;
      }
      .swiper-wrapper{
        padding-top: 16px;
      }
    }
    .swiper-block{
      margin-top: 51px;
      padding-top: 16px;
      overflow: hidden;
      .swiper-row{
        padding-top: 16px;
        animation: 5s linear infinite;
        display: flex;
        &:hover {
          animation-play-state: paused;
        }
      .swiper-slide {
        width: 360px;
        margin-right: 32px !important;
      }
    }
    }

  }
}

@media screen and (max-width: 1019px) {
  .docmentary-wrap {
    background: @black-bg-primary-V2;
    padding: 64px 0;
    .main-wrap {
      overflow: hidden;
      margin: auto;
      position: relative;
      .gd-title {
        display: none;
      }
      .gd-desc {
        width: 343px;
        font-size: 24px;
        .medium-font();
        color: @black-text-primary-V2;
        margin-bottom: 24px;
        text-align: center;
        margin-left: auto;
        margin-right: auto;
      }
      .gd-small {
        font-size: 16px;
        color: @black-text-tertiary-V2;
        padding-left: 16px;
        padding-right: 16px;
      }
      .gd-btn {
        margin-top: 24px;
        margin-left: 16px;
        margin-right: 16px;
        height: 48px;
        line-height: 48px;
        border-radius: 16px;
        padding: 0 24px;
        font-size: 16px;
        .medium-font();
        color: @black-bg-primary-V2;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: @black-btn-tertiary-default-V2;

        .td-btn-arrow {
          width: 20px;
          height: 20px;
        }
      }
      .top-num-main {
        display: none;
      }
    }
    .gd-swiper-wrap {
      display: none;
    }
    .swiper-block{
      display: none;
    }
  }
}
</style>
