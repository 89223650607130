<template>
  <section>
    <div class="joint-venture" :class="theme">
      <div class="list-viewer">
        <div class="icon-list">
          <!-- <vue-marquee-slider id="marquee-slider-rc" style="width: 100%;" :speed="50000" :autoWidth='true'
                    :space="48"> -->
          <div class="item img1" v-for="(item, index) in ventureList" :key="item.img_name + index">
            <img class="default_img" :src="item.default_img" :alt="item.img_name" />
            <img class="img-white" :src="item.white_img" :alt="item.img_name" />
            <img class="img-black" :src="item.black_img" :alt="item.img_name" />
          </div>
          <!-- </vue-marquee-slider> -->
        </div>
      </div>
    </div>
    <div class="joint-venture-h5">
      <h2 class="joint-h5-title">{{ $t('wxtInfo.partners') }}</h2>
      <section class="joint-h5-imgs">
        <img class="joint-item-img" v-for="item in h5VentrueList" :src="item.src" :key="item.name" alt="" />
      </section>
    </div>
  </section>
</template>
<script>
import { mapState, mapGetters } from 'vuex';
export default {
    data() {
        return {
            theme: 'black',
            ventureList: [],
            h5VentrueList: []
        }
    },
    computed: {
        ...mapState(['locale', 'newTheme']),
    },
    watch: {
        newTheme(newVal) {
            this.theme = newVal
        }
    },
    created() {
        this.theme = this.newTheme;
        const arr = ['yahoo', 'investing', 'Binance', 'TradingView', 'TheTimes', 'KingData', 'ForesightNews', 'Bloomberg', 'Coinepeaker', 'BTCcom', 'jinsecaijing', 'ChipPay', 'CoinGecko', 'Coinsph', 'Tscientific', 'feixiaohao'];
        const list = arr.map(item => {
            return {
                img_name: item,
                default_img: require(`~/assets/img/index/jointVentureIcon/${item}_hui.svg`),
                white_img: require(`~/assets/img/index/jointVentureIcon/${item}_white.svg`),
                black_img: require(`~/assets/img/index/jointVentureIcon/${item}_black.svg`)
            };
        });
        this.ventureList = list.concat(list)

    },
  mounted() {
    this.theme = this.newTheme;
    const arr = [
      'yahoo',
      'investing',
      'Binance',
      'TradingView',
      'TheTimes',
      'KingData',
      'ForesightNews',
      'Bloomberg',
      'Coinepeaker',
      'BTCcom',
      'jinsecaijing',
      'ChipPay',
      'CoinGecko',
      'Coinsph',
      'Tscientific',
      'feixiaohao'
    ];
    const list = arr.map(item => {
      return {
        img_name: item,
        default_img: require(`~/assets/img/index/jointVentureIcon/${item}_hui.svg`),
        white_img: require(`~/assets/img/index/jointVentureIcon/${item}_white.svg`),
        black_img: require(`~/assets/img/index/jointVentureIcon/${item}_black.svg`)
      };
    });
    this.ventureList = list.concat(list);
    const h5Arr = [
      'yahoo',
      'investing',
      'Binance',
      'TradingView',
      'TheTimes',
      'KingData',
      'ForesightNews',
      'Bloomberg',
      'Coinepeaker',
      'BTCcom',
      'jinsecaijing',
      'ChipPay',
      'CoinGecko',
      'Coinsph',
      'Tscientific',
      'feixiaohao'
    ];
    this.h5VentrueList = h5Arr.map(item => ({
      name: item,
      src: require(`~/assets/img/index/jointVentureIcon/${item}_black.svg`)
    }));
  }
};
</script>
<style lang='less' scoped>
.black {
  &.joint-venture {
    background: @black-bg-primary-V2;

    .title,
    .sub-title {
      color: @black-text-secondary-V2;
    }

    .icon-list .item {
      &:hover {
        .default_img,
        .img-white {
          display: none;
        }

        .img-black {
          display: block;
        }
      }
    }
  }
}

.joint-venture {
  .list-viewer {
    align-items: center;
    cursor: pointer;
    width: 100%;
    position: relative;
    overflow: hidden;

    &:hover {
      .item {
        animation-play-state: paused;
      }
    }
  }

  .icon-list {
    display: flex;
    width: 600%;
    margin-top: 80px;
    margin-bottom: 80px;
    // transition: all;
    position: relative;
    overflow: hidden;

    .item {
      // display: ;
      height: 50px;
      // box-sizing: border-box;
      animation: move 90s linear infinite;
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      margin-right: 48px;

      .default_img,
      .img-white,
      .img-black {
        height: 50px;
      }

      .img-white,
      .img-black {
        display: none;
      }

      &:hover {
        .default_img,
        .img-black {
          display: none;
        }

        .img-white {
          display: block;
        }
      }
    }

    @keyframes move {
      0% {
        transform: translateX(40px);
        visibility: visible;
      }

      100% {
        transform: translateX(-3000px);
      }
    }
  }
}

@media screen and (min-width: 769px) {
  .joint-venture-h5 {
    display: none;
  }
}


@media screen and (min-width: 769px) and (max-width: 1200px) {
  .joint-venture {
    display: block;
  }
  .container-wrap {
    .main-wrap {
      .pxToVwPad(width, 956);
    }
  }
}

@media screen and (max-width: 1019px) {
  .joint-venture {
    display: none;
  }
  .joint-venture-h5 {
    display: block;
    margin-bottom: 80px;
    .joint-h5-title{
        font-size: 24px;
        color: @black-text-primary-V2;
        .bold-font();
        text-align: center;
        margin-bottom: 35px;
    }
    .joint-h5-imgs {
      display: flex;
      gap: 9px 16px;
      flex-wrap: wrap;
      margin-left: 16px;
      margin-right: 16px;
      justify-content: space-between;
    }

    .joint-item-img {
      width: 102px;
      height: 34px;
    }
  }
}
</style>
